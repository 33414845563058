<template>
  <div>
    <v-card-text class="px-0 py-0">
      <v-btn
        class="mt-8"
        color="primary"
        rounded
        block
        large
        type="submit"
        :disabled="!valid"
        :loading="loading"
        to="/my-partner/enterPartnerCode"
      >
        Enter Partner Code
      </v-btn>
      <v-btn
        class="mt-8"
        color="primary"
        rounded
        block
        large
        type="submit"
        :disabled="!valid"
        :loading="loading"
        to="/my-partner/showMyCode"
      >
        Show My Code
      </v-btn>
      <v-btn
        class="mt-8"
        color="primary"
        rounded
        block
        large
        type="submit"
        :disabled="!valid"
        :loading="loading"
        to="/my-partner/new"
      >
        Send Partner Invitation
      </v-btn>
    </v-card-text>
    <v-spacer />
  </div>
</template>

<script>
export default {
  data() {
    return {
      valid: true,
      loading: false
    };
  },
  mounted() {}
};
</script>

<style scoped>
.theme--primary.v-card {
  color: '#1F6070';
}
</style>
